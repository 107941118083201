(function($){
  // Hero Image Carousel Naviation
  $('.carousel__navigation .left').on('click', function() {
    changeCarouselImage('previous');
  });

  $('.carousel__navigation .right').on('click', function() {
    changeCarouselImage('next');
  });

  
  function changeCarouselImage(direction) {
    // Get Index of Images
    var total = $('.carousel__images .carousel__image').length;
    
    // Get Current Image
    var current = $('.carousel__images .carousel__image.active').data('index');
    
    // Get New Image to Show
    var goTo = current;
    if (direction === 'next') {
      goTo += 1;
      // If clicked 'next' and was on last image, go back to first
      if (goTo >= total) goTo = 0;
    } else {
      goTo -= 1;
      // If clicked 'previous' and was on first image, go back to last
      if (goTo < 0) goTo = (total - 1);
    }

    // remove active classes
    $('.carousel__images .carousel__image').removeClass('active');

    // Add Active Class to selected image
    const goToSelector = '.carousel__images .carousel__image[data-index='+goTo+']';
    $(goToSelector).addClass('active');
  }
})(jQuery)
